import style from './projectdetail.module.scss';
import {
  ScrollTopButton,
  Header,
  ImageWrapper,
  HelmetWrapper,
} from '../../components';
import Slide1 from '../../assets/img/slider/me1.png';
import { XIcon, NavigateArrow, ProjectRightArrow } from '../../assets/icon';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { LanguageData } from '../../utils/Language';
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

const ProjectDetail = () => {
  const location = useLocation();

  const currentId = location.search.split('=')[1];

  const currentLanguage = localStorage.getItem('lang');
  const langData = LanguageData[currentLanguage].projectDetail;

  const [data, setData] = useState();

  const [navigateArtist, setNavigateArtist] = useState({
    prevArtist: {
      title: '',
      id: '',
    },
    nextArtist: {
      title: '',
      id: '',
    },
  });

  useEffect(() => {
    AxiosConfig.post(ApiRoutes.projectDetail, {
      id: currentId,
    })
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });

    AxiosConfig.get(ApiRoutes.projectList)
      .then(res => {
        let currentIndex = res.data.findIndex(i => i._id === currentId);
        let nextArtist =
          currentIndex !== res.data.length && res.data[currentIndex + 1];
        let prevArtist = currentIndex && res.data[currentIndex - 1];

        setNavigateArtist({
          prevArtist: prevArtist
            ? { title: prevArtist.title[currentLanguage], id: prevArtist._id }
            : { title: '', id: '' },
          nextArtist: nextArtist
            ? { title: nextArtist.title[currentLanguage], id: nextArtist._id }
            : { title: '', id: '' },
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const [isShareOpen, setShareOpen] = useState(false);

  return (
    <HelmetWrapper
      title={`Mural Istanbul - ${data ? data.title[currentLanguage] : ''}`}
      children={
        <div className={style.projectDetail}>
          <Header />
          <h1>Projects</h1>
          {data && (
            <>
              <div className={style.wrapper}>
                <div className={style.info}>
                  <div className={style.left}>
                    <h4>
                      <span className={style.project}>{langData.projects}</span>
                      <span className={style.mural}>Mural East</span> <XIcon />
                      {data.brand[currentLanguage]}
                    </h4>
                    <h3>{data.title[currentLanguage]}</h3>
                  </div>
                  <div className={style.right}>
                    <ul>
                      <li>
                        <span>{langData.artist}:</span>
                        {data.artist}
                      </li>
                      <li>
                        <span>{langData.location}:</span>
                        {data.location}
                      </li>
                      <li>
                        <span>{langData.year}:</span>
                        {data.year[currentLanguage]}
                      </li>
                    </ul>
                    {isShareOpen ? (
                      <div className={style.shareWrapper}>
                        <WhatsappShareButton
                          title={`${data.title[currentLanguage]} / Mural-East`}
                          url={window.location.href}
                        >
                          Whatsapp
                        </WhatsappShareButton>
                        <FacebookShareButton
                          title={`${data.title[currentLanguage]} / Mural-East`}
                          quote={`${data.title[currentLanguage]} / Mural-East`}
                          url={window.location.href}
                        >
                          Facebook
                        </FacebookShareButton>
                        <LinkedinShareButton
                          title={`${data.title[currentLanguage]} / Mural-East`}
                          url={window.location.href}
                        >
                          LinkedIn
                        </LinkedinShareButton>
                        <EmailShareButton
                          subject={`${data.title[currentLanguage]} / Mural-East`}
                          url={window.location.href}
                        >
                          Email
                        </EmailShareButton>
                      </div>
                    ) : (
                      <button
                        className={style.shareButton}
                        onClick={() => setShareOpen(true)}
                      >
                        {langData.share}
                      </button>
                    )}
                  </div>
                </div>
                <div className={style.head}>
                  <ImageWrapper image={data.coverPhoto} />
                </div>
                <div className={style.contentWrapper}>
                  <div className={style.contentInfo}>
                    {langData.about}
                    <ProjectRightArrow />
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.text[currentLanguage],
                    }}
                    className={style.content}
                  />
                </div>

                <div className={style.imageContent}>
                  {data.imageList &&
                    data.imageList.map(item => (
                      <ImageWrapper
                        image={item.photo}
                        className={style[item.photoType]}
                      />
                    ))}
                </div>
              </div>
              <div className={style.navigate}>
                <a
                  href={`/projects/${navigateArtist.prevArtist.title}?id=${navigateArtist.prevArtist.id}`}
                  onClick={e => {
                    if (!navigateArtist.prevArtist.id) {
                      e.preventDefault();
                    }
                  }}
                  className={`${style.left} ${
                    !navigateArtist.prevArtist.id && style.disabled
                  }`}
                >
                  <NavigateArrow left />

                  <div>
                    <h6>{langData.prev}</h6>
                    <h5>{navigateArtist.prevArtist.title}</h5>
                  </div>
                </a>
                <div className={style.scrollTop}>
                  <ScrollTopButton />
                </div>
                <a
                  className={`${style.right} ${
                    !navigateArtist.nextArtist.id && style.disabled
                  }`}
                  href={`/projects/${navigateArtist.nextArtist.title}?id=${navigateArtist.nextArtist.id}`}
                  onClick={e => {
                    if (!navigateArtist.nextArtist.id) {
                      e.preventDefault();
                    }
                  }}
                >
                  <div>
                    <h6>{langData.next}</h6>
                    <h5>{navigateArtist.nextArtist.title}</h5>
                  </div>
                  <NavigateArrow />
                </a>
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default ProjectDetail;
