export const LanguageData = {
  en: {
    header: {
      projects: 'Projects',
      festival: 'Festival',
      guide: 'Guide Tours & Workshops',
      about: 'About',
      contact: 'Contact',
    },
    home: {
      readMore: 'Read More',
      projects: 'Projects',
      artist: 'Artists',
    },
    about: {
      title: 'About',
      team: 'Mural-East Team',
      partners: 'Partners/ Collaborators',
    },
    specialFestival: {
      upcoming: 'Upcoming',
      date: '13-14 JULY 2023',
      artist: 'Artists',
      committee: 'Festival Committee',
      muralOnMap: 'Murals on the Map',
      byArtist: 'By Artist',
      select: 'Please Select',
      festivalPartner: 'Festival Partners',
      instagram1: 'Festival Page',
      instagram2: 'Page',
      next: 'Next Festival',
      prev: 'Previous Festival',
    },
    festival: {
      upcoming: 'Upcoming',
      moreInformation: 'More Information',
      allYear: 'All Years',
      allArtist: 'All Artists',
      festivalHistory: 'Festival History',
      readMore: 'Read More',
      muralOnMap: 'Murals on the Map',
      byYear: 'By Year',
      byArtist: 'By Artist',
      select: 'Please Select',
      artistPage: `Artist's Page`,
      goToLocation: 'Go To Location',
    },
    projectDetail: {
      projects: 'Projects',
      artist: 'Artist',
      location: 'Location',
      year: 'Year',
      share: 'Share',
      prev: 'Previous Project',
      next: 'Next Project',
      about: 'About The Project',
    },
    projects: {
      projects: 'Projects',
      readMore: 'Read More',
      next: 'Next Projects',
      prev: 'Previous Projects',
    },
    contact: {
      mainTitle: 'Contact',
      title: 'Get in touch',
      application: 'Apply for a festival',
      sendMessage: 'Send us a message',
      form: {
        name: 'Name',
        yourName: 'Your Name',
        email: 'Email',
        yourEmail: 'Your Email',
        subject: 'Subject',
        yourSubject: 'Your Subject',
        message: 'Message',
        yourMessage: 'Your Message',
        send: 'Send',
      },
    },
  },
  tr: {
    header: {
      projects: 'Projeler',
      festival: 'Festival',
      guide: 'Rehberli Turlar & Workshoplar',
      about: 'Hakkımızda',
      contact: 'İletişim',
    },
    home: {
      readMore: 'Devamını Gör',
      projects: 'Projeler',
      artist: 'Sanatçılar',
    },
    about: {
      title: 'Hakkımızda',
      team: 'Mural-East Ekibi',
      partners: 'Partnerlerimiz / İş Ortaklarımız',
    },
    specialFestival: {
      upcoming: 'Yaklaşan Etkinlik',
      date: '13-14 TEMMUZ 2023',
      artist: 'Sanatçılar',
      committee: 'Festival Komitesi',
      muralOnMap: 'Murallar Haritada',
      byArtist: 'Sanatçıya Göre',
      select: 'Seçim Yapınız',
      festivalPartner: 'Festival Partnerleri',
      instagram1: 'Festival',
      instagram2: 'Hesabı',
      next: 'Sonraki Festival',
      prev: 'Önceki Festival',
    },
    festival: {
      upcoming: 'Yaklaşan Etkinlik',
      moreInformation: 'Daha Fazla Bilgi',
      allYear: 'Tüm Yıllar',
      allArtist: 'Tüm Sanatçılar',
      festivalHistory: 'Festival Tarihi',
      readMore: 'Devamını Gör',
      muralOnMap: 'Murallar Haritada',
      byYear: 'Yıla Göre',
      byArtist: 'Sanatçıya Göre',
      select: 'Seçim Yapınız',
      artistPage: 'Artist Sayfası',
      goToLocation: 'Lokasyona Git',
    },
    projectDetail: {
      projects: 'Projeler',
      artist: 'Sanatçı',
      location: 'Konum',
      year: 'Yıl',
      share: 'Paylaş',
      prev: 'Önceki Proje',
      next: 'Sonraki Proje',
      about: 'Proje Hakkında',
    },
    projects: {
      readMore: 'Devamını Gör',
      projects: 'Projeler',
      next: 'Sonraki Projeler',
      prev: 'Önceki Projeler',
    },
    contact: {
      mainTitle: 'İletişim',
      title: 'İletişime geç',
      application: 'Festivale başvur',
      sendMessage: 'Bize yaz',
      form: {
        name: 'İsim',
        yourName: 'İsminiz',
        email: 'Email',
        yourEmail: 'Emailiniz',
        subject: 'Konu',
        yourSubject: 'Konunuz',
        message: 'Mesaj',
        yourMessage: 'Mesajınız',
        send: 'Gönder',
      },
    },
  },
};
