import {
  Header,
  ScrollTopButton,
  ImageWrapper,
  HelmetWrapper,
} from '../../components';
import style from './artistdetail.module.scss';
import { NavigateArrow } from '../../assets/icon';
import { useState, useEffect } from 'react';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useLocation } from 'react-router-dom';
import { LanguageData } from '../../utils/Language';

const ArtistDetail = () => {
  const currentLanguage = localStorage.getItem('lang');
  const langData = LanguageData[currentLanguage].contact;
  const location = useLocation();
  const currentArtist = location.pathname.split('/')[2];
  const currentId = location.search.split('=')[1];
  const [data, setData] = useState();
  const [navigateArtist, setNavigateArtist] = useState({
    prevArtist: {
      title: '',
      id: '',
    },
    nextArtist: {
      title: '',
      id: '',
    },
  });

  useEffect(() => {
    AxiosConfig.post(ApiRoutes.artistDetail, {
      id: currentId,
    })
      .then(res => {
        setData(res.data);

        const muralDataNew = res.data.muralList;

        console.log(muralDataNew);
      })
      .catch(err => {
        console.log(err);
      });

    AxiosConfig.get(ApiRoutes.artistList)
      .then(res => {
        let currentIndex = res.data.findIndex(i => i._id === currentId);
        let nextArtist =
          currentIndex !== res.data.length && res.data[currentIndex + 1];
        let prevArtist = currentIndex && res.data[currentIndex - 1];

        setNavigateArtist({
          prevArtist: prevArtist
            ? { title: prevArtist.name, id: prevArtist._id }
            : { title: '', id: '' },
          nextArtist: nextArtist
            ? { title: nextArtist.name, id: nextArtist._id }
            : { title: '', id: '' },
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <HelmetWrapper
      title={`Mural Istanbul - ${data ? data.name : ''}`}
      children={
        <div className={style.artistDetail}>
          <Header />
          <h1 className={style.mainTitle}>Artists</h1>
          {data && (
            <div className={style.wrapper}>
              <div className={style.head}>
                <h1>{data.name}</h1>
                <div className={style.subInfo}>
                  <a href="/festival">Festival</a>
                  <div className={style.divider} />
                  <h5>All Artist </h5>
                </div>
                <div className={style.rightInfo}>
                  <h3>
                    Country: <span>{data.country[currentLanguage]}</span>
                  </h3>
                  <a
                    href={`https://www.instagram.com/${
                      data.instagram ? data.instagram : 'muralistanbul'
                    }/`}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    instagram
                  </a>
                </div>
              </div>

              <div className={style.workWrapper}>
                {data.muralList.map(item => (
                  <div className={`${style.work} ${style[item.photoType]}`}>
                    <ImageWrapper image={item.photo} />
                    <h6>{item.subInfo}</h6>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={style.navigate}>
            <a
              href={`/artist/${navigateArtist.prevArtist.title}?id=${navigateArtist.prevArtist.id}`}
              onClick={e => {
                if (!navigateArtist.prevArtist.id) {
                  e.preventDefault();
                }
              }}
              className={`${style.left} ${
                !navigateArtist.prevArtist.id && style.disabled
              }`}
            >
              <NavigateArrow left />

              <div>
                <h6>Previous Artist</h6>
                <h5>{navigateArtist.prevArtist.title}</h5>
              </div>
            </a>
            <div className={style.scrollTop}>
              <ScrollTopButton />
            </div>
            <a
              className={`${style.right} ${
                !navigateArtist.nextArtist.id && style.disabled
              }`}
              href={`/artist/${navigateArtist.nextArtist.title}?id=${navigateArtist.nextArtist.id}`}
              onClick={e => {
                if (!navigateArtist.nextArtist.id) {
                  e.preventDefault();
                }
              }}
            >
              <div>
                <h6>Next Artist</h6>
                <h5>{navigateArtist.nextArtist.title}</h5>
              </div>
              <NavigateArrow />
            </a>
          </div>
        </div>
      }
    />
  );
};

export default ArtistDetail;
