import style from './contact.module.scss';
import { Header, HelmetWrapper } from '../../components';
import { useState } from 'react';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { LanguageData } from '../../utils/Language';
import { useEffect } from 'react';

export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Contact = () => {
  const [data, setData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [message, setMessage] = useState({
    type: '',
    text: '',
  });

  const sendContact = () => {
    if (!data.name.length) {
      setMessage({
        type: 'error',
        text: {
          tr: 'Lütfen isim giriniz.',
          en: 'Please enter name.',
        },
      });
    } else if (!data.email.length) {
      setMessage({
        type: 'error',
        text: {
          tr: 'Lütfen email giriniz.',
          en: 'Please enter email.',
        },
      });
    } else if (!validateEmail(data.email)) {
      setMessage({
        type: 'error',
        text: {
          tr: 'Lütfen geçerli bir email giriniz.',
          en: 'Please enter a valid email.',
        },
      });
    } else if (!data.subject.length) {
      setMessage({
        type: 'error',
        text: {
          tr: 'Lütfen konu giriniz.',
          en: 'Please enter subject.',
        },
      });
    } else if (!data.message.length) {
      setMessage({
        type: 'error',
        text: {
          tr: 'Lütfen mesaj giriniz.',
          en: 'Please enter message.',
        },
      });
    } else {
      AxiosConfig.post(ApiRoutes.contact, data)
        .then(res => {
          setMessage({
            type: 'success',
            text: {
              tr: 'Mesajınız başarıyla iletildi.',
              en: 'Your message has been successfully delivered.',
            },
          });
          setData({
            name: '',
            email: '',
            subject: '',
            message: '',
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const currentLanguage = localStorage.getItem('lang');
  const langData = LanguageData[currentLanguage].contact;

  return (
    <HelmetWrapper
      title={`Mural Istanbul - ${langData.title}`}
      children={
        <div className={style.contact}>
          <Header />
          <h1>{langData.mainTitle}</h1>
          <div className={style.wrapper}>
            <div className={style.column}>
              <div className={style.top}>
                <h2>{langData.title}</h2>
                <a
                  className={style.mail}
                  href="mailto:info@mural-east.com"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <span>{langData.form.email}:</span> info@mural-east.com
                </a>
                <a
                  href="https://instagram.com/muraleast"
                  target={'_blank'}
                  rel="noreferrer"
                  className={style.instagram}
                >
                  instagram
                </a>
                <h2>{langData.application}</h2>
                <a
                  className={style.mail}
                  href="mailto:info@muralistanbul.org"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <span>{langData.form.email}:</span> info@muralistanbul.org
                </a>
              </div>
              <h6>© 2022 Mural-East</h6>
            </div>
            <div className={style.column}>
              <h2>{langData.sendMessage}</h2>
              <form>
                <label>
                  <h4>{langData.form.name}</h4>
                  <input
                    onChange={e => {
                      setData({
                        ...data,
                        name: e.target.value,
                      });
                    }}
                    value={data.name}
                    type={'text'}
                    placeholder={langData.form.yourName}
                  />
                </label>
                <label>
                  <h4>{langData.form.email}</h4>
                  <input
                    onChange={e => {
                      setData({
                        ...data,
                        email: e.target.value,
                      });
                    }}
                    value={data.email}
                    type={'email'}
                    placeholder={langData.form.yourEmail}
                  />
                </label>
                <label>
                  <h4>{langData.form.subject}</h4>
                  <input
                    onChange={e => {
                      setData({
                        ...data,
                        subject: e.target.value,
                      });
                    }}
                    value={data.subject}
                    type={'text'}
                    placeholder={langData.form.yourSubject}
                  />
                </label>
                <label>
                  <h4>{langData.form.message}</h4>
                  <textarea
                    onChange={e => {
                      setData({
                        ...data,
                        message: e.target.value,
                      });
                    }}
                    value={data.message}
                    placeholder={langData.form.yourMessage}
                  />
                </label>
                {message.type && (
                  <h6 className={style[message.type]}>{message.text.tr}</h6>
                )}
                <button
                  onClick={e => {
                    e.preventDefault();
                    sendContact();
                  }}
                >
                  {langData.form.send}
                </button>
              </form>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Contact;
