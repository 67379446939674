const MuralLogo = () => {
  return (
    <svg
      width="208"
      height="31"
      viewBox="0 0 208 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100.01 0H91.5219V30.473H111.552V22.1101H100.01V0Z"
        fill="#4C2DC1"
      />
      <path
        d="M68.439 30.473H76.8811V19.0399H79.9804V30.473H88.4226V0H68.439V30.473ZM76.8811 8.3629H79.9804V12.2121H76.8811V8.3629Z"
        fill="#4C2DC1"
      />
      <path
        d="M65.5479 9.64597V8.9357C65.5479 3.75758 61.7547 0 56.5275 0H45.6106V30.473H54.0527V19.0399H57.152V30.473H65.6404V16.7258H62.1942C64.2758 15.1449 65.5479 12.6245 65.5479 9.64597ZM57.152 12.1663H54.0527V8.3629H57.152V12.1663Z"
        fill="#4C2DC1"
      />
      <path
        d="M9.99177 7.62971L6.86934 0H0V30.473H8.39586V15.2365H11.6339V30.473H20.0298V0H13.9006L9.99177 7.62971Z"
        fill="#4C2DC1"
      />
      <path
        d="M101.629 11.3415L105.954 18.6733L118.49 11.2956L114.142 3.98669L101.629 11.3415Z"
        fill="#4C2DC1"
      />
      <path
        d="M120.202 30.473H140.232V22.0643H128.644V19.0399H137.132V11.4331H128.644V8.3629H140.232V0H120.202V30.473Z"
        fill="#4C2DC1"
      />
      <path
        d="M143.007 30.473H151.449V19.0399H154.549V30.473H162.991V0H143.007V30.473ZM151.472 8.3629H154.572V12.2121H151.472V8.3629Z"
        fill="#4C2DC1"
      />
      <path
        d="M166.09 19.0399H178.372V22.1101H166.09V30.473H186.074V11.4331H173.769V8.40872H186.074V0H166.09V19.0399Z"
        fill="#4C2DC1"
      />
      <path
        d="M188.016 0V8.3629H193.822V30.473H202.264V8.3629H208V0H188.016Z"
        fill="#4C2DC1"
      />
      <path
        d="M34.3699 22.1101H31.2706V0H22.8284V21.2853C22.8284 22.7746 23.1754 24.1722 23.6148 25.1345C24.1005 26.2343 24.9794 27.357 25.7427 28.1131C26.6679 29.0754 27.6855 29.7398 28.8883 30.221C30.1604 30.748 31.4093 31 32.7739 31C34.1386 31 35.48 30.748 36.6596 30.2668C37.9086 29.7398 38.9032 29.0754 39.8977 28.1589C40.7766 27.2882 41.4011 26.4176 41.9793 25.1345C42.5113 23.9431 42.812 22.8204 42.812 21.2853V0H34.3699V22.1101Z"
        fill="#4C2DC1"
      />
    </svg>
  );
};

const XIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.00006 11L11.0001 1" stroke="white" />
      <path d="M1.00006 1L11.0001 11" stroke="white" />
    </svg>
  );
};

const RedirectArrow = () => {
  return (
    <svg
      width="66"
      height="49"
      viewBox="0 0 66 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="31.9999" width="64" height="1" fill="#424242" />
      <rect
        x="1"
        width="33"
        height="0.999999"
        transform="rotate(90 1 0)"
        fill="#424242"
      />
      <rect
        width="22.0139"
        height="1"
        transform="matrix(0.710441 -0.703756 0.713762 0.700388 48 48.0777)"
        fill="#424242"
      />
      <rect
        width="22.1366"
        height="1"
        transform="matrix(-0.710441 -0.703756 -0.713762 0.700388 64.4406 31.5787)"
        fill="#424242"
      />
      <path
        d="M65.2899 32.299L64.0399 33.5981L63.5399 31.5L61.0399 28.5L65.2899 32.299Z"
        fill="#424242"
      />
    </svg>
  );
};

const GoTopArrow = () => {
  return (
    <svg
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="34.6718"
        y="71"
        width="69.6852"
        height="1.2963"
        transform="rotate(-90 34.6718 71)"
        fill="#4C2DC1"
      />
      <rect
        width="49.1525"
        height="1.30563"
        transform="matrix(-0.703757 -0.710441 0.700388 -0.713762 69.2632 36.4758)"
        fill="#4C2DC1"
      />
      <rect
        width="48.5518"
        height="1.30563"
        transform="matrix(-0.703756 0.710441 0.700388 0.713762 34.1686 1.06226)"
        fill="#4C2DC1"
      />
      <path
        d="M35.1039 1.07929e-09L36.6008 1.64352L33.6071 1.64352L35.1039 1.07929e-09Z"
        fill="#4C2DC1"
      />
    </svg>
  );
};

const NavigateArrow = ({ left }) => {
  return left ? (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13_416)">
        <path
          d="M1.33334 36.5561L72 36.5562L72 35.231L1.33334 35.231L1.33334 36.5561Z"
          fill="#4C2DC1"
        />
        <path
          d="M1.57797 36.5562L36.9897 1.19482L36.0447 0.260018L0.632935 35.6214L1.57797 36.5562Z"
          fill="#4C2DC1"
        />
        <path
          d="M36.0565 71.9999L1.07739 37.0706L2.02243 36.1358L37.0015 71.0651L36.0565 71.9999Z"
          fill="#4C2DC1"
        />
        <path
          d="M-4.05688e-05 36.1146L1.66663 34.5845L1.66663 37.6448L-4.05688e-05 36.1146Z"
          fill="#4C2DC1"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_416">
          <rect
            width="72"
            height="72"
            fill="white"
            transform="translate(72 72) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13_416)">
        <path
          d="M70.6667 35.4438L0 35.4438L0 36.769L70.6667 36.769V35.4438Z"
          fill="#4C2DC1"
        />
        <path
          d="M70.422 35.4438L35.0103 70.8052L35.9553 71.74L71.3671 36.3786L70.422 35.4438Z"
          fill="#4C2DC1"
        />
        <path
          d="M35.9435 0.000110681L70.9226 34.9294L69.9776 35.8642L34.9985 0.934916L35.9435 0.000110681Z"
          fill="#4C2DC1"
        />
        <path
          d="M72 35.8854L70.3334 37.4155V34.3552L72 35.8854Z"
          fill="#4C2DC1"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_416">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const RightArrowBigIcon = () => {
  return (
    <svg
      width="162"
      height="163"
      viewBox="0 0 162 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="80.2406" width="159" height="3" fill="white" />
      <rect
        x="78.7732"
        y="160.295"
        width="112.947"
        height="3"
        transform="rotate(-45.1355 78.7732 160.295)"
        fill="white"
      />
      <rect
        width="111.567"
        height="3"
        transform="matrix(-0.705432 -0.708777 -0.708777 0.705432 159.576 79.076)"
        fill="white"
      />
      <path
        d="M162 81.2405L158.25 84.7046L158.25 77.7764L162 81.2405Z"
        fill="white"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 33.1128L33.1127 2.0001" stroke="#424242" stroke-width="3" />
      <path
        d="M33.1123 33.1128L1.99961 2.0001"
        stroke="#424242"
        stroke-width="3"
      />
    </svg>
  );
};

const HamburgerIcon = () => {
  return (
    <svg
      width="44"
      height="24"
      viewBox="0 0 44 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 2H44" stroke="#424242" stroke-width="3" />
      <path d="M0 12H44" stroke="#424242" stroke-width="3" />
      <path d="M0 22H44" stroke="#424242" stroke-width="3" />
    </svg>
  );
};

const MobileMenuSeperator = () => {
  return (
    <svg
      width="48"
      height="22"
      viewBox="0 0 48 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 21.54L47.134 0.999839" stroke="#4C2DC1" />
    </svg>
  );
};

const YearSliderArrow = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13_404)">
        <path
          d="M70.6667 35.4434L0 35.4434L0 36.7685L70.6667 36.7685V35.4434Z"
          fill="#D9D9D9"
        />
        <path
          d="M70.422 35.4443L35.0103 70.8057L35.9553 71.7405L71.3671 36.3791L70.422 35.4443Z"
          fill="#D9D9D9"
        />
        <path
          d="M35.9435 0.000354822L70.9226 34.9297L69.9776 35.8645L34.9985 0.93516L35.9435 0.000354822Z"
          fill="#D9D9D9"
        />
        <path
          d="M71.9999 35.8856L70.3333 37.4158V34.3555L71.9999 35.8856Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_404">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ProjectRightArrow = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13_391)">
        <path d="M70.6667 35.4437H0V36.7689H70.6667V35.4437Z" fill="#4C2DC1" />
        <path
          d="M70.422 35.4438L35.0103 70.8052L35.9553 71.74L71.3671 36.3786L70.422 35.4438Z"
          fill="#4C2DC1"
        />
        <path
          d="M35.9435 -7.24244e-05L70.9226 34.9293L69.9776 35.8641L34.9985 0.934733L35.9435 -7.24244e-05Z"
          fill="#4C2DC1"
        />
        <path
          d="M72 35.8854L70.3334 37.4155V34.3552L72 35.8854Z"
          fill="#4C2DC1"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_391">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MarkerIcon = () => {
  return (
    <svg
      width="68"
      height="99"
      viewBox="0 0 68 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="34" r="18" fill="white" />
      <path
        d="M34.0065 6.67617e-07C28.7459 -0.0010471 23.5568 1.2312 18.8474 3.59978C14.1381 5.96836 10.037 9.40867 6.8669 13.65C3.69676 17.8914 1.54403 22.8181 0.578078 28.0425C-0.38787 33.2669 -0.140682 38.6465 1.30019 43.758C1.75688 45.5989 2.41417 47.383 3.26003 49.0776C10.2468 61.9773 34.0065 99 34.0065 99C34.0065 99 57.7662 61.974 64.74 49.0776C65.5858 47.383 66.2431 45.5989 66.6998 43.758C68.1404 38.6475 68.3878 33.2691 67.4225 28.0457C66.4573 22.8223 64.3057 17.8964 61.137 13.6553C57.9684 9.41421 53.869 5.97365 49.1613 3.60419C44.4537 1.23474 39.2661 0.000995073 34.0065 6.67617e-07ZM34.0065 49.5C30.6087 49.5058 27.3097 48.346 24.6495 46.2103C21.9893 44.0747 20.1257 41.0898 19.3638 37.7445C18.6019 34.3992 18.9868 30.8916 20.4555 27.7961C21.9242 24.7006 24.3898 22.2007 27.4484 20.7056C30.507 19.2105 33.9774 18.8089 37.2914 19.5665C40.6054 20.3241 43.5666 22.196 45.6902 24.8758C47.8137 27.5556 48.9737 30.8844 48.9803 34.3171C48.9869 37.7499 47.8396 41.0832 45.7263 43.7712C44.3267 45.5569 42.5455 46.9998 40.516 47.9919C38.4864 48.9839 36.2611 49.4995 34.0065 49.5Z"
        fill="#4C2DC1"
      />
    </svg>
  );
};

const HoverCardIcon = () => {
  return (
    <svg
      width="54"
      height="25"
      viewBox="0 0 54 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M54 24.5H0L27 0L54 24.5Z" fill="#4C2DC1" />
    </svg>
  );
};

export {
  MuralLogo,
  XIcon,
  RedirectArrow,
  GoTopArrow,
  NavigateArrow,
  RightArrowBigIcon,
  CloseIcon,
  HamburgerIcon,
  MobileMenuSeperator,
  YearSliderArrow,
  ProjectRightArrow,
  MarkerIcon,
  HoverCardIcon,
};
