import style from './slider.module.scss';
import Slider from 'react-slick';
import Slide1 from '../../assets/img/slider/me1.png';
import { RightArrowBigIcon, XIcon } from '../../assets/icon';
import { useState, useRef } from 'react';
import { LanguageData } from '../../utils/Language';
import { ImageWrapper } from '../../components';
import { slugify } from '../../helpers/slugify';
import { useEffect } from 'react';
import useWindowDimensions from '../../helpers/windowWidth';

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

const HomeSlider = ({ data }) => {
  const currentLanguage = localStorage.getItem('lang');
  const langData = LanguageData[currentLanguage].home;
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: false,
    nextArrow: <></>,
    prevArrow: <></>,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const slideRef = useRef();

  const [sliderData, setSliderData] = useState();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (data) {
      const currentData = [
        ...data.slider.map(item => {
          return {
            photo: item.photo,
            photoMobile: item.photoMobile,
            brand:
              item.type === 'projects'
                ? item.rawData.brand[currentLanguage]
                : '',
            type: item.type,
            id: item.rawData._id,
            title:
              item.type === 'artist'
                ? item.rawData.name
                : item.rawData.title[currentLanguage],
          };
        }),
      ];
      setSliderData(data.isShuffle ? shuffle(currentData) : currentData);
    }
  }, [data]);

  return (
    <div className={style.slider}>
      <Slider ref={slideRef} {...settings}>
        {sliderData &&
          sliderData.map(item => {
            return (
              <div className={style.slideItem}>
                <a
                  href={`/${item.type}/${
                    item.type === 'projects' ? slugify(item.title) : item.title
                  }?id=${item.id}`}
                  className={style.shadowClick}
                />
                <div className={style.info}>
                  <a
                    className={style.topTitle}
                    href={`/${item.type}/${
                      item.type === 'projects'
                        ? slugify(item.title)
                        : item.title
                    }?id=${item.id}`}
                  >
                    <span
                      className={`${style.project} ${
                        item.type === 'projects' && style.line
                      }`}
                    >
                      {langData[item.type]}
                    </span>
                    {item.type === 'projects' && (
                      <>
                        <span className={style.mural}>Mural East</span>
                        <XIcon /> {item.brand}
                      </>
                    )}
                  </a>

                  <a
                    href={`/${item.type}/${
                      item.type === 'projects'
                        ? slugify(item.title)
                        : item.title
                    }?id=${item.id}`}
                    className={style.mainTitle}
                  >
                    {item.title}
                  </a>
                  <a
                    href={`/${item.type}/${
                      item.type === 'projects'
                        ? slugify(item.title)
                        : item.title
                    }?id=${item.id}`}
                    className={style.readMore}
                  >
                    {langData.readMore}
                  </a>
                  <div className={style.dotWrapper}>
                    {sliderData.map((i, index) => (
                      <button
                        style={{
                          opacity: currentSlide === index ? 1 : 0.5,
                        }}
                        onClick={() => slideRef.current.slickGoTo(index)}
                        className={style.dot}
                      />
                    ))}
                  </div>
                </div>
                <ImageWrapper
                  image={width < 900 ? item.photoMobile : item.photo}
                />
                <button
                  onClick={() => slideRef.current.slickNext()}
                  className={style.rightArrow}
                >
                  <RightArrowBigIcon />
                </button>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default HomeSlider;
