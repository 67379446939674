import style from './specialfestival.module.scss';
import { Header, ScrollTopButton, ImageWrapper } from '../../components';
import { GoTopArrow, NavigateArrow } from '../../assets/icon';
import Slider from 'react-slick';
import Slide1 from '../../assets/img/slider/me1.png';
import Slide2 from '../../assets/img/slider/me2.png';
import Slide3 from '../../assets/img/slider/me3.png';
import MemberImage from '../../assets/img/member-image.png';
import Map from 'google-map-react';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useState, useEffect } from 'react';
import { darkMap } from '../../assets/data/googleMapStyle';
import { LanguageData } from '../../utils/Language';
import { useLocation } from 'react-router-dom';
import EmptyArtist from '../../assets/img/empty_artist.jpg';

const settings = {
  dots: false,
  nextArrow: <></>,
  prevArrow: <></>,
  infinite: false,
  speed: 500,
  slidesToShow: 2.5,
  autoplaySpeed: 3000,
  autoplay: true,
  slidesToScroll: 1,
  pauseOnHover: false,
};

const AnyReactComponent = ({ text, image, name, id }) => (
  <a href={`/artist/${name}?id=${id}`} className={style.marker}>
    <h3>{text}</h3>
    <ImageWrapper image={image} />
  </a>
);

const SpecialFestival = () => {
  const location = useLocation();
  const currentId = location.search.split('=')[1];
  const [togleMenu, setTogleMenu] = useState('festival');

  const [data, setData] = useState();
  const currentLanguage = localStorage.getItem('lang');
  const langData = LanguageData[currentLanguage].specialFestival;

  const [navigateArtist, setNavigateArtist] = useState({
    prevArtist: {
      title: '',
      id: '',
    },
    nextArtist: {
      title: '',
      id: '',
    },
  });

  useEffect(() => {
    AxiosConfig.get(ApiRoutes.festival2023)
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });

    AxiosConfig.get(ApiRoutes.festival)
      .then(res => {
        let currentIndex = res.data.festivalList
          .sort((a, b) => parseFloat(a.year) - parseFloat(b.year))
          .findIndex(i => i._id === currentId);
        let nextArtist =
          currentIndex !== res.data.festivalList.length &&
          res.data.festivalList[currentIndex + 1];
        let prevArtist =
          currentIndex && res.data.festivalList[currentIndex - 1];

        setNavigateArtist({
          prevArtist: prevArtist
            ? { title: prevArtist.year, id: prevArtist._id }
            : { title: '', id: '' },
          nextArtist: nextArtist
            ? { title: nextArtist.year, id: nextArtist._id }
            : { title: '', id: '' },
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const [defaultCenter, setDefaultCenter] = useState({
    long: 29.003481395616728,
    lat: 41.00698521009365,
  });

  const [selectedArtist, setSelectedArtist] = useState('');

  return (
    <div className={style.events}>
      <Header />
      {data && (
        <>
          <h1 className={style.mainTitle}>2023 Festival</h1>

          <div className={style.wrapper}>
            <div className={style.head}>
              <h1>{data.festival.specialTitle[currentLanguage]}</h1>
              <div className={style.subInfo}>
                <h3>Festival</h3>
                <div className={style.divider} />
                <h5>{langData.upcoming} </h5>
              </div>
            </div>
            <div className={style.headInfo}>
              <div className={style.subHead}>
                {data.festival.specialDate[currentLanguage]}
              </div>
            </div>

            <div className={style.featuredArtist}>
              <div className={style.info}>
                <h3> {langData.artist}</h3>
              </div>
              {data && (
                <div className={style.artistWrapper}>
                  {data.festival.artistList.map(item => {
                    return (
                      <a
                        href={`/artist/${item.artist.name}?id=${item.artist._id}`}
                      >
                        {item.artist.name}
                        <ImageWrapper
                          image={
                            item.artist.photo ? item.artist.photo : EmptyArtist
                          }
                        />
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {data && (
        <div className={style.team}>
          <h3>{langData.committee}</h3>
          <div className={style.teamWrapper}>
            {data.comittee.map(item => {
              return (
                <div className={style.member}>
                  <ImageWrapper image={item.photo ? item.photo : EmptyArtist} />

                  <h5>
                    {item.name} <br />{' '}
                    <span>{item.title[currentLanguage]}</span>
                  </h5>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={style.muralMap}>
        {data && (
          <div className={style.head}>
            <h4>{langData.muralOnMap}</h4>
            <div className={style.filterWrapper}>
              <label>
                <span>{langData.byArtist}:</span>
                <select
                  onChange={e => {
                    setSelectedArtist(e.target.value);
                  }}
                >
                  <option value={''}>{langData.select}</option>
                  {data.festival.artistList.map(item => {
                    return (
                      <option value={item.artist.name}>
                        {item.artist.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
        )}
        <div className={style.muralMapWrapper}>
          <div className={style.map}>
            {data && (
              <Map
                bootstrapURLKeys={{
                  key: 'AIzaSyB_Ky81tzMJzLiCMHyUJ94L2I_Cgl_yX2A',
                }}
                defaultZoom={13}
                options={{ styles: darkMap }}
                defaultCenter={{
                  lat: defaultCenter.lat,
                  lng: defaultCenter.long,
                }}
              >
                {data.festival.artistList
                  .filter(i => i.artist.name.includes(selectedArtist))
                  .map(item => {
                    return item.artist.muralList.map(i => {
                      return (
                        <AnyReactComponent
                          lat={i.maps.latitude}
                          lng={i.maps.longitude}
                          text={item.artist.name}
                          image={i.photo}
                          name={item.artist.name}
                          id={item.artist._id}
                        />
                      );
                    });
                  })}
              </Map>
            )}
          </div>
        </div>
      </div>
      <div className={style.partners}>
        <h4>{langData.festivalPartner}</h4>
        <div className={style.partnerWrapper}>
          {data &&
            data.festival.partnerList.map(i => {
              return <ImageWrapper image={i.logo} />;
            })}
        </div>
      </div>

      <a
        href="https://www.instagram.com/muralistanbul/"
        target={'_blank'}
        rel="noreferrer"
        className={style.instagramLink}
      >
        {langData.instagram1} <span>instagram</span> {langData.instagram2}
      </a>

      <div className={style.navigate}>
        <a
          href={`/festival/${navigateArtist.prevArtist.title}?id=${navigateArtist.prevArtist.id}`}
          onClick={e => {
            if (!navigateArtist.prevArtist.id) {
              e.preventDefault();
            }
          }}
          className={!navigateArtist.prevArtist.id && style.disabled}
        >
          <NavigateArrow left />

          <div>
            <h6>{langData.prev}</h6>
            <h5>{navigateArtist.prevArtist.title}</h5>
          </div>
        </a>
        <ScrollTopButton />
        <a
          className={`${style.right} ${
            !navigateArtist.nextArtist.id && style.disabled
          }`}
          href={`/festival/${navigateArtist.nextArtist.title}?id=${navigateArtist.nextArtist.id}`}
          onClick={e => {
            if (!navigateArtist.nextArtist.id) {
              e.preventDefault();
            }
          }}
        >
          <div>
            <h6>{langData.next}</h6>
            <h5>{navigateArtist.nextArtist.title}</h5>
          </div>
          <NavigateArrow />
        </a>
      </div>
    </div>
  );
};

export default SpecialFestival;
