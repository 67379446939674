import { FRONTEND_URL } from '../../utils/FrontendUrl';
import style from './imagewrapper.module.scss';

const ImageWrapper = ({ image, className }) => {
  const currentImage =
    (image && image.split('http').length > 1) || image.includes('data')
      ? image
      : `${FRONTEND_URL}${image}`;

  return (
    <img src={currentImage} className={`${className} ${style.imageWrapper}`} />
  );
};

export default ImageWrapper;
