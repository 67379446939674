import { Helmet } from 'react-helmet';

const HelmetWrapper = ({ children, title }) => {
  const langDesc = {
    tr: "Mural East; sokak sanatları, mural ve graffiti kültürünün Türkiye'deki yaratıcısı olarak bu kültüre ilişik üretimlerle kapsamını her geçen gün genişleten, bu kültürün gelişimine kapı aralayan projeler üreten ve uygulayan oluşumdur.",
    en: 'Mural East, the creator of street arts, mural and graffiti culture in Turkey, is an enterprise that expands its scope progressively with productions related to this culture, produces, and implements projects promoting the development of it.',
  };

  const currenLanguage = localStorage.getItem('lang');
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={langDesc[currenLanguage]}></meta>
        <meta
          name="keywords"
          content={`Sokak Sanatı
Street Art
Street Artist
Sokak Sanatçısı
Mural Art
Muralism
Muralist
Urban Art
Graffiti
Graffitici
Grafiti Sanatı
Graffiti Sanatı
Mural Istanbul Festival
Mural Festival Istanbul
Mural-Ist
Street Art Festival
Street Art Tour
Kadıköy
Yeldeğirmeni`}
        ></meta>
      </Helmet>
      {children}
    </>
  );
};

export default HelmetWrapper;
