import style from './guide.module.scss';
import { Header, ScrollTopButton, HelmetWrapper } from '../../components';
import Slide1 from '../../assets/img/slider/me1.png';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useState, useEffect } from 'react';
import { LanguageData } from '../../utils/Language';

const GuideCard = ({ type, title, date, time, location, image }) => {
  return (
    <div className={style.guideCard}>
      <div className={style.info}>
        <h6>{type}</h6>
        <h2>{title}</h2>
        <div className={style.line} />
        <h4>
          <span>Register</span>
          <a href="mailto:info@mural-east.com" target="_blank" rel="noreferrer">
            info@mural-east.com
          </a>
        </h4>
        <div className={style.line} />
        <h3>
          <span className={style.date}>{`${date.split('-')[2]}-${
            date.split('-')[1]
          }-${date.split('-')[0]}`}</span>
          <br />
          <span className={style.time}>{time}</span>
          <br />
          <span className={style.location}>{location}</span>
        </h3>
      </div>
      <img src={image} alt={title} />
    </div>
  );
};

const Guide = () => {
  const currentLanguage = localStorage.getItem('lang');
  const langData = LanguageData[currentLanguage].contact;

  const [data, setData] = useState();

  useEffect(() => {
    AxiosConfig.get(ApiRoutes.guides)
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <HelmetWrapper
      title={`Mural Istanbul - Guide Tours & Workshop`}
      children={
        <div className={style.guide}>
          <Header />
          <h1>Guide Tours & Workshop</h1>
          <div className={style.wrapper}>
            {data &&
              data.map(item => {
                return (
                  <GuideCard
                    time={item.time}
                    title={item.title[currentLanguage]}
                    location={item.location[currentLanguage]}
                    date={item.date}
                    type={item.type}
                    image={item.coverPhoto}
                  />
                );
              })}
          </div>
          <ScrollTopButton />
        </div>
      }
    />
  );
};

export default Guide;
