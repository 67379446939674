import {
  Header,
  ScrollTopButton,
  ImageWrapper,
  HelmetWrapper,
} from '../../components';
import { XIcon, RedirectArrow, RightArrowBigIcon } from '../../assets/icon';
import Slide1 from '../../assets/img/slider/me1.png';
import style from './projects.module.scss';
import { useState, useEffect } from 'react';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { slugify } from '../../helpers/slugify';
import { LanguageData } from '../../utils/Language';

const ProjectCard = ({ brand, title, image, id, lang }) => {
  return (
    <a
      href={`/projects/${slugify(title)}?id=${id}`}
      className={style.projectCard}
    >
      <div className={style.info}>
        <h4>
          <span className={style.project}>{lang.title}</span>
          <span className={style.mural}>Mural East</span> <XIcon /> {brand}
        </h4>
        <h3>{title}</h3>
        <a href="/projects">
          <RedirectArrow /> <span>{lang.readMore}</span>
        </a>
      </div>
      <ImageWrapper image={image} />
    </a>
  );
};

const Projects = () => {
  const [activePage, setActivePage] = useState(0);
  const [data, setData] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const currentLanguage = localStorage.getItem('lang');
  const langData = LanguageData[currentLanguage].projects;

  useEffect(() => {
    AxiosConfig.post(ApiRoutes.projects, {
      pageNumber: activePage,
    })
      .then(res => {
        console.log(res.data);
        setData(res.data.projectList);
        setTotalPage(res.data.totalPage.count);
        // setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [activePage]);

  return (
    <HelmetWrapper
      title={`Mural Istanbul - ${langData.projects}`}
      children={
        <div className={style.projects}>
          <Header />
          <h1>{langData.projects}</h1>
          <div className={style.projectWrapper}>
            {data &&
              data.map(item => {
                return (
                  <ProjectCard
                    brand={item.brand[currentLanguage]}
                    title={item.title.tr}
                    image={item.coverPhoto}
                    id={item._id}
                    lang={{
                      title: langData.projects,
                      readMore: langData.readMore,
                    }}
                  />
                );
              })}
          </div>
          <div className={style.paginationWrapper}>
            <button
              className={`${style.prevWrapper} ${
                activePage === 0 && style.disabled
              }`}
              onClick={() => {
                if (activePage !== 0) {
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }, 250);
                  setActivePage(activePage - 1);
                }
              }}
            >
              <RightArrowBigIcon />
              <h6>{langData.prev}</h6>
            </button>
            <div className={style.numberWrapper}>
              {[...new Array(totalPage)].map((item, index) => (
                <button
                  onClick={() => {
                    setActivePage(index);
                    setTimeout(() => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      });
                    }, 250);
                  }}
                  className={index === activePage && style.active}
                >
                  {index + 1} {index + 1 !== totalPage && '/'}
                </button>
              ))}
            </div>

            <button
              className={`${style.nextWrapper} ${
                activePage + 1 === totalPage && style.disabled
              }`}
              onClick={async () => {
                if (activePage + 1 !== totalPage) {
                  await setActivePage(activePage + 1);
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }, 250);
                }
              }}
            >
              <h6>{langData.next}</h6>
              <RightArrowBigIcon />
            </button>
          </div>
          <ScrollTopButton />
        </div>
      }
    />
  );
};

export default Projects;
