import {
  MuralLogo,
  MobileMenuSeperator,
  HamburgerIcon,
  CloseIcon,
} from '../../assets/icon';
import style from './header.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { LanguageData } from '../../utils/Language';
import { useEffect } from 'react';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';

const MobileMenu = ({
  currentLocation,
  lang,
  currentLanguage,
  change,
  specialFestival,
}) => {
  return (
    <div className={style.mobileMenu}>
      <ul>
        <li className={currentLocation === 'projects' ? style.active : null}>
          <a href="/projects">
            {lang.projects}
            <br />
            <MobileMenuSeperator />
          </a>
        </li>
        <li className={currentLocation === 'festival' ? style.active : null}>
          <a href="/festival">
            {lang.festival}
            <br />
            <MobileMenuSeperator />
          </a>
          {specialFestival &&
            specialFestival.map(i => {
              return (
                <a
                  className={style.hide}
                  href={`/festival/special/${i.year}?id=${i._id}`}
                >
                  {i.year} {lang.festival}
                  <br />
                  <MobileMenuSeperator />
                </a>
              );
            })}
        </li>
        <li
          className={
            currentLocation === 'guide-tours-workshop' ? style.active : null
          }
        >
          <a href="/guide-tours-workshop">{lang.guide}</a>
        </li>
      </ul>
      <div className={style.bottom}>
        <div className={`${style.navigate} ${style[currentLanguage]}`}>
          <a
            className={currentLocation === 'about' ? style.activeSub : null}
            href="/about"
          >
            {lang.about}
          </a>
          <a
            className={currentLocation === 'contact' ? style.activeSub : null}
            href="/contact"
          >
            {lang.contact}
          </a>
        </div>
        <select onChange={e => change(e)} value={currentLanguage}>
          <option value={'tr'}>TR</option>
          <option value={'en'}>EN</option>
        </select>
      </div>
    </div>
  );
};

const Header = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[1];
  const [isOpen, setOpen] = useState(false);
  const [specialFestival, setSpecialFestival] = useState();
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem('lang')
  );
  const langData = LanguageData[currentLanguage].header;

  const navigate = useNavigate();

  useEffect(() => {
    AxiosConfig.get(ApiRoutes.festival).then(res => {
      setSpecialFestival(res.data.festivalList.filter(i => i.isSpecial));
    });
  }, []);

  return (
    <>
      <header
        style={{
          position: isOpen ? 'fixed' : 'static',
        }}
        className={`${style.header} `}
      >
        <a className={style.logo} href="/">
          <MuralLogo />
        </a>
        <button
          onClick={() => {
            setOpen(!isOpen);
          }}
          className={style.hamburgerMenu}
        >
          {isOpen ? <CloseIcon /> : <HamburgerIcon />}
        </button>
        <ul>
          <li className={currentLocation === 'projects' ? style.active : null}>
            <a href="/projects">{langData.projects}</a>
          </li>
          <li className={currentLocation === 'festival' ? style.active : null}>
            <a href="/festival">{langData.festival}</a>
            {specialFestival && specialFestival.length ? (
              <div className={style.inner}>
                {specialFestival.map(i => {
                  return (
                    <a
                      className={style.hide}
                      href={`/festival/special/${i.year}?id=${i._id}`}
                    >
                      {i.year} {langData.festival}
                    </a>
                  );
                })}
              </div>
            ) : null}
          </li>
          <li
            className={
              currentLocation === 'guide-tours-workshop' ? style.active : null
            }
          >
            <a href="/guide-tours-workshop">{langData.guide}</a>
          </li>
        </ul>
        <div className={style.right}>
          <div className={`${style.navigate} ${style[currentLanguage]}`}>
            <a
              className={currentLocation === 'about' ? style.activeSub : null}
              href="/about"
            >
              {langData.about}
            </a>
            <a
              className={currentLocation === 'contact' ? style.activeSub : null}
              href="/contact"
            >
              {langData.contact}
            </a>
          </div>
          <select
            onChange={e => {
              localStorage.setItem('lang', e.target.value);
              setCurrentLanguage(e.target.value);
              navigate(0);
            }}
            value={currentLanguage}
          >
            <option value={'tr'}>TR</option>
            <option value={'en'}>EN</option>
          </select>
        </div>
      </header>
      {isOpen && (
        <MobileMenu
          change={e => {
            localStorage.setItem('lang', e.target.value);
            setCurrentLanguage(e.target.value);
            navigate(0);
          }}
          currentLanguage={currentLanguage}
          lang={langData}
          currentLocation={currentLocation}
          specialFestival={specialFestival}
        />
      )}
    </>
  );
};

export default Header;
