import style from './banner.module.scss';
import { RightArrowBigIcon } from '../../assets/icon';
import Slide1 from '../../assets/img/slider/me1.png';
import ImageWrapper from '../ImageWrapper/ImageWrapper';

const Banner = ({ lang, image, title, link }) => {
  return (
    <>
      <div className={style.bannerTop}>
        <RightArrowBigIcon />
        <h5>
          {lang.upcoming} <span>/ {title}</span>
        </h5>
      </div>
      <div className={style.banner}>
        <ImageWrapper image={image} />
      </div>
      <a className={style.more} href={link}>
        {lang.moreInformation}
      </a>
    </>
  );
};

export default Banner;
