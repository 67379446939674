export const ApiRoutes = {
  home: 'website/home',
  projects: 'website/projects',
  projectDetail: 'website/projectDetail',
  projectList: 'website/projectList',
  guides: 'website/guides',
  contact: 'website/sendContact',
  about: 'website/about',
  festival: 'website/festival',
  festivalDetail: 'website/festivalDetail',
  artistDetail: 'website/artistDetail',
  festival2023: 'website/2023Festival',
  artistList: 'website/artistList',
};
