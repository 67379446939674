import { Header, HomeSlider, HelmetWrapper } from '../../components';

import { useEffect, useState } from 'react';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';

const Home = () => {
  const [data, setData] = useState();

  useEffect(() => {
    AxiosConfig.get(ApiRoutes.home)
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <HelmetWrapper
      title="Mural Istanbul"
      children={
        <div>
          <Header />
          {data && <HomeSlider data={data} />}
        </div>
      }
    />
  );
};

export default Home;
