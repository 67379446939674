import {
  Home,
  Projects,
  ProjectDetail,
  About,
  Guide,
  Contact,
  Festival,
  SpecialFestival,
  YearDetail,
  ArtistDetail,
} from '../containers';

export const routes = [
  {
    title: 'Home',
    path: '*',
    component: <Home />,
    protected: false,
  },
  {
    title: 'Projects',
    path: '/projects',
    component: <Projects />,
    protected: false,
  },
  {
    title: 'Project Detail',
    path: '/projects/:id',
    component: <ProjectDetail />,
    protected: false,
  },
  {
    title: 'About',
    path: '/about',
    component: <About />,
    protected: false,
  },
  {
    title: 'Guide',
    path: '/guide-tours-workshop',
    component: <Guide />,
    protected: false,
  },
  {
    title: 'Contact',
    path: '/contact',
    component: <Contact />,
    protected: false,
  },
  {
    title: 'Festival',
    path: '/festival',
    component: <Festival />,
    protected: false,
  },
  {
    title: '2023 Festival',
    path: '/festival/special/:year',
    component: <SpecialFestival />,
    protected: false,
  },
  {
    title: '2022 Festival',
    path: '/festival/:year',
    component: <YearDetail />,
    protected: false,
  },
  {
    title: '2023 Festival',
    path: '/artist/:name',
    component: <ArtistDetail />,
    protected: false,
  },
];
