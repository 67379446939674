import {
  Header,
  ScrollTopButton,
  ImageWrapper,
  HelmetWrapper,
} from '../../components';
import style from './yeardetail.module.scss';
import { NavigateArrow } from '../../assets/icon';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';

import Year1 from '../../assets/img/years-image/years-1.png';
import Year2 from '../../assets/img/years-image/years-2.png';
import Year3 from '../../assets/img/years-image/years-3.png';
import Year4 from '../../assets/img/years-image/years-4.png';
import Year5 from '../../assets/img/years-image/years-5.png';

const WorkData = [
  {
    artist: 'Aryz / Kadiköy / 2020',
    image: Year1,
    type: 'full',
  },
  {
    artist: 'Aryz / Kadiköy / 2020',
    image: Year2,
    type: 'half',
  },
  {
    artist: 'Aryz / Kadiköy / 2020',
    image: Year3,
    type: 'half',
  },
  {
    artist: 'Aryz / Kadiköy / 2020',
    image: Year4,
    type: 'full',
  },
  {
    artist: 'Aryz / Kadiköy / 2020',
    image: Year5,
    type: 'full',
  },
];

const YearDetail = () => {
  const location = useLocation();
  const currentYear = location.pathname.split('/')[2];
  const currentId = location.search.split('=')[1];
  const [data, setData] = useState();
  const [navigateArtist, setNavigateArtist] = useState({
    prevArtist: {
      title: '',
      id: '',
    },
    nextArtist: {
      title: '',
      id: '',
    },
  });

  const [muralData, setMuralData] = useState([]);

  useEffect(() => {
    AxiosConfig.post(ApiRoutes.festivalDetail, {
      id: currentId,
    })
      .then(res => {
        console.log(res.data);
        setData(res.data);
        setMuralData(
          res.data.artistList
            .map(i => i.artist.muralList.map(i => i))
            .flat()
            .filter(i => {
              return i.subInfo.includes(currentYear);
            })
        );
      })
      .catch(err => {
        console.log(err);
      });

    AxiosConfig.get(ApiRoutes.festival)
      .then(res => {
        let currentIndex = res.data.festivalList
          .sort((a, b) => parseFloat(a.year) - parseFloat(b.year))
          .findIndex(i => i._id === currentId);
        let nextArtist =
          currentIndex !== res.data.festivalList.length &&
          res.data.festivalList[currentIndex + 1];
        let prevArtist =
          currentIndex && res.data.festivalList[currentIndex - 1];

        setNavigateArtist({
          prevArtist: prevArtist
            ? { title: prevArtist.year, id: prevArtist._id }
            : { title: '', id: '' },
          nextArtist: nextArtist
            ? { title: nextArtist.year, id: nextArtist._id }
            : { title: '', id: '' },
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <HelmetWrapper
      title={`Mural Istanbul - ${data ? data.year : ''}`}
      children={
        <div className={style.yearDetail}>
          <Header />
          <h1 className={style.mainTitle}>Years</h1>
          <div className={style.wrapper}>
            <div className={style.head}>
              <h1>
                {currentYear.split('%20').map(i => (
                  <> {i}</>
                ))}
              </h1>
              <div className={style.subInfo}>
                <h3>Festival</h3>
                <div className={style.divider} />
                <h5>All Years </h5>
              </div>
            </div>
            <div className={style.artistTitle}>
              {data &&
                data.artistList
                  .sort((a, b) => a.artist.name.localeCompare(b.artist.name))
                  .map((item, index) => (
                    <a
                      href={`/artist/${item.artist.name}?id=${item.artist._id}`}
                    >
                      {item.artist.name}{' '}
                      {data.artistList.length - 1 !== index && <span>- </span>}
                    </a>
                  ))}
            </div>
            <div className={style.workWrapper}>
              {muralData &&
                muralData
                  .filter(i => !data.banMuralList.includes(i._id))
                  .map(item => (
                    <div className={`${style.work} ${style[item.photoType]}`}>
                      <ImageWrapper image={item.photo} />
                      <h6>{item.subInfo}</h6>
                    </div>
                  ))}
            </div>
          </div>
          <div className={style.navigate}>
            <a
              href={`/festival/${navigateArtist.prevArtist.title}?id=${navigateArtist.prevArtist.id}`}
              onClick={e => {
                if (!navigateArtist.prevArtist.id) {
                  e.preventDefault();
                }
              }}
              className={`${style.left} ${
                !navigateArtist.prevArtist.id && style.disabled
              }`}
            >
              <NavigateArrow left />

              <div>
                <h6>Previous Festival</h6>
                <h5>{navigateArtist.prevArtist.title}</h5>
              </div>
            </a>
            <ScrollTopButton />
            <a
              className={`${style.right} ${
                !navigateArtist.nextArtist.id && style.disabled
              }`}
              href={`/festival/${navigateArtist.nextArtist.title}?id=${navigateArtist.nextArtist.id}`}
              onClick={e => {
                if (!navigateArtist.nextArtist.id) {
                  e.preventDefault();
                }
              }}
            >
              <div>
                <h6>Next Festival</h6>
                <h5>{navigateArtist.nextArtist.title}</h5>
              </div>
              <NavigateArrow />
            </a>
          </div>
        </div>
      }
    />
  );
};

export default YearDetail;
