import { GoTopArrow } from '../../assets/icon';

const ScrollTopButton = () => {
  return (
    <button
      onClick={() =>
        window.scroll({
          behavior: 'smooth',
          top: 0,
          left: 0,
        })
      }
      style={{
        cursor: 'pointer',
        marginBottom: '20px',
        marginTop: '40px',
        background: 'transparent',
      }}
    >
      <GoTopArrow />
    </button>
  );
};

export default ScrollTopButton;
