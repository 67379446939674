import style from './festival.module.scss';
import {
  Header,
  ScrollTopButton,
  Banner,
  ImageWrapper,
  HelmetWrapper,
} from '../../components';
import {
  GoTopArrow,
  RedirectArrow,
  YearSliderArrow,
  MarkerIcon,
  HoverCardIcon,
} from '../../assets/icon';
import Slider from 'react-slick';
import Slide1 from '../../assets/img/slider/me1.png';
import Slide2 from '../../assets/img/slider/me2.png';
import Slide3 from '../../assets/img/slider/me3.png';
import Map, { fitBounds } from 'google-map-react';
import { darkMap } from '../../assets/data/googleMapStyle';
import { useState, useEffect, useRef } from 'react';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { LanguageData } from '../../utils/Language';
import useWindowDimensions from '../../helpers/windowWidth';

const AnyReactComponent = ({
  text,
  image,
  name,
  id,
  subInfo,
  lat,
  lng,
  lang,
}) => (
  <div className={style.marker}>
    <MarkerIcon />
    {/* <h3>{text}</h3> */}
    <div className={style.markerCard}>
      <ImageWrapper image={image} />
      <div className={style.info}>
        <h5>{name}</h5>
        <h6>{subInfo.split('/')[2]}</h6>
        <a href={`/artist/${name}?id=${id}`} className={style.markerArtist}>
          {lang.artistPage}
        </a>
        <a
          target={'_blank'}
          href={`https://www.google.com/maps/place/${lat},${lng}`}
          className={style.markerLocation}
        >
          {lang.goToLocation}
        </a>
      </div>
    </div>
  </div>
);
const Festival = () => {
  const [togleMenu, setTogleMenu] = useState('festival');
  const currentLanguage = localStorage.getItem('lang');
  const langData = LanguageData[currentLanguage].festival;

  const [data, setData] = useState();
  const [artistData, setArtistData] = useState();
  const [mapArtistData, setMapArtistData] = useState();
  const [festivalHistory, setFestivalHistory] = useState();
  const [bannerFestival, setBannerFestival] = useState();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [festivalSort, setFestivalSort] = useState();
  const [timing, setTiming] = useState(false);

  const settings = {
    dots: false,
    nextArrow: <></>,
    prevArrow: <></>,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToScroll: 1,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplaySpeed: 1500,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    AxiosConfig.get(ApiRoutes.festival)
      .then(res => {
        console.log('RES', res.data);
        setData(res.data.festivalList);
        const artistListData = res.data.festivalList
          .map(i => i.artistList)
          .flat()
          .map(i => {
            return {
              artist: i.artist,
            };
          });
        let uniqueArtistlist = artistListData.reduce((unique, o) => {
          if (!unique.some(obj => obj.artist._id === o.artist._id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setArtistData(uniqueArtistlist);
        setMapArtistData(uniqueArtistlist);
        setFestivalHistory(res.data.festivalHistory[0]);
        setBannerFestival(res.data.festivalSettings[0]);
        setFestivalSort(res.data.festivalSettings[0].festivalSort);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const [defaultCenter, setDefaultCenter] = useState({
    long: 29.003481395616728,
    lat: 41.00698521009365,
  });

  const [selectedArtist, setSelectedArtist] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const slideRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setTiming(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (selectedYear) {
      const firstArr = data
        .filter(i => i.year === selectedYear)[0]
        .artistList.map(i => {
          return {
            artist: i.artist,
          };
        });

      setMapArtistData(firstArr);

      console.log(firstArr);
    } else if (data) {
      const artistListData = data
        .map(i => i.artistList)
        .flat()
        .map(i => {
          return {
            artist: i.artist,
          };
        });
      let uniqueArtistlist = artistListData.reduce((unique, o) => {
        if (!unique.some(obj => obj.artist._id === o.artist._id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      setMapArtistData(uniqueArtistlist);
    }
  }, [selectedYear]);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 900) {
      setDefaultCenter({
        long: 29.029080374967776,
        lat: 40.99653215402955,
      });
    }
  }, [width]);

  console.log(data);

  return (
    <HelmetWrapper
      title={`Mural Istanbul - Festival`}
      children={
        <div className={style.events}>
          <Header />
          <h1 className={style.mainTitle}>Festival</h1>
          <div className={style.wrapper}>
            {bannerFestival && bannerFestival.isLive && (
              <Banner
                lang={{
                  upcoming: langData.upcoming,
                  moreInformation: langData.moreInformation,
                }}
                image={bannerFestival.coverPhoto}
                title={bannerFestival.title[currentLanguage]}
                link={bannerFestival.link}
              />
            )}
            <div className={style.head}>
              <h1>Mural Istanbul Festival</h1>
            </div>
          </div>

          <div className={style.programs}>
            {data && (
              <div className={style.programHead}>
                <h4>{langData.allYear}</h4>
              </div>
            )}
            <div className={style.programWrapper}>
              {data && (
                <div className={style.sliderArrow}>
                  <button
                    onClick={() => slideRef.current.slickPrev()}
                    className={`${style.sliderLeft} ${
                      !currentSlide && style.deactive
                    }`}
                  >
                    <YearSliderArrow />
                  </button>
                  <button
                    className={`${
                      data.filter(i => i.isLive).length - 3 === currentSlide &&
                      style.deactive
                    }`}
                    onClick={() => {
                      slideRef.current.slickNext();
                    }}
                  >
                    <YearSliderArrow />
                  </button>
                </div>
              )}
              {festivalSort && (
                <Slider ref={slideRef} className={style.slide} {...settings}>
                  {data &&
                    festivalSort &&
                    festivalSort
                      .map(item => {
                        return data.find(i => i._id === item);
                      })
                      .filter(i => i.isLive)
                      .map(festivalItem => {
                        const item = festivalItem;
                        return (
                          <a
                            href={
                              item.isSpecial
                                ? `/festival/special/${item.year}?id=${item._id}`
                                : `/festival/${item.year}?id=${item._id}`
                            }
                            className={style.programCard}
                          >
                            <ImageWrapper image={item.coverPhoto} />
                            {/* <img src={Slide1} alt={item.title.tr} /> */}
                            <div className={style.info}>
                              <h6>{item.year}</h6>
                              <h5>{item.title.tr}</h5>
                              <button>
                                <RedirectArrow /> {langData.readMore}
                              </button>
                            </div>
                          </a>
                        );
                      })}
                </Slider>
              )}
            </div>
          </div>

          <div className={style.artists}>
            <h4>{langData.allArtist}</h4>
            <div className={style.artistWrapper}>
              {artistData &&
                artistData
                  .sort((a, b) => a.artist.name.localeCompare(b.artist.name))
                  .map(item => {
                    return (
                      <a
                        href={`/artist/${item.artist.name}?id=${item.artist._id}`}
                      >
                        {item.artist.name}
                      </a>
                    );
                  })}
            </div>
          </div>

          <div className={style.muralMap}>
            {data && mapArtistData && (
              <div className={style.head}>
                <h4>{langData.muralOnMap}</h4>
                <div className={style.filterWrapper}>
                  <label>
                    <span>{langData.byYear}:</span>
                    <select
                      onChange={e => {
                        setSelectedYear(e.target.value);
                      }}
                    >
                      <option value={''}>{langData.select}</option>
                      {data
                        .filter(i => i.isLive)
                        .map(item => {
                          return <option value={item.year}>{item.year}</option>;
                        })}
                    </select>
                  </label>
                  <label>
                    <span>{langData.byArtist}:</span>
                    <select
                      onChange={e => {
                        if (e.target.value) {
                          const artistRaw = JSON.parse(e.target.value);
                          // setSelectedArtist(e.target.value);
                          setSelectedArtist(artistRaw.name);
                          if (
                            artistRaw.muralList &&
                            artistRaw.muralList.length
                          ) {
                            const muralFind = artistRaw.muralList.filter(
                              i => i.maps.longitude && i.maps.latitude
                            );
                            console.log(muralFind);
                            if (muralFind.length) {
                              setDefaultCenter({
                                long: parseFloat(muralFind[0].maps.longitude),
                                lat: parseFloat(muralFind[0].maps.latitude),
                              });
                            }
                          }
                        } else {
                          setSelectedArtist('');
                        }
                      }}
                    >
                      <option value={''}>{langData.select}</option>
                      {mapArtistData
                        .filter(
                          item =>
                            item.artist.muralList
                              .map(i => i.maps)
                              .filter(i => i.longitude && i.latitude).length
                        )
                        .map(item => {
                          return (
                            <option value={JSON.stringify(item.artist)}>
                              {item.artist.name}
                            </option>
                          );
                        })}
                    </select>
                  </label>
                </div>
              </div>
            )}
            <div className={style.muralMapWrapper}>
              <div className={style.map}>
                {mapArtistData && (
                  <Map
                    bootstrapURLKeys={{
                      key: 'AIzaSyB_Ky81tzMJzLiCMHyUJ94L2I_Cgl_yX2A',
                    }}
                    defaultZoom={13}
                    options={{ styles: darkMap }}
                    defaultCenter={{
                      lat: defaultCenter.lat,
                      lng: defaultCenter.long,
                    }}
                    center={{
                      lat: defaultCenter.lat,
                      lng: defaultCenter.long,
                    }}
                    onChange={e => {
                      console.log(e);
                    }}
                  >
                    {mapArtistData
                      .filter(i => i.artist.name.includes(selectedArtist))
                      .map(item => {
                        return item.artist.muralList.map(i => {
                          return (
                            <AnyReactComponent
                              lat={i.maps.latitude}
                              lng={i.maps.longitude}
                              text={item.artist.name}
                              image={i.photo}
                              name={item.artist.name}
                              id={item.artist._id}
                              subInfo={i.subInfo}
                              lang={langData}
                            />
                          );
                        });
                      })}
                  </Map>
                )}
              </div>
            </div>
            {festivalHistory && (
              <div className={style.festivalHistory}>
                <h4>{langData.festivalHistory}</h4>
                <div className={style.content}>
                  <div className={style.paragraph}>
                    <h5>{festivalHistory.title[currentLanguage]}</h5>
                    <p>
                      {festivalHistory.paragraph[currentLanguage]
                        .split(/\n\s*\n/)
                        .map((i, index) => {
                          return (
                            <>
                              {index ? (
                                <>
                                  <br />
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                              {i}
                            </>
                          );
                        })}
                    </p>
                  </div>
                  <ImageWrapper image={festivalHistory.coverPhoto} />
                </div>
              </div>
            )}
          </div>
          <ScrollTopButton />
        </div>
      }
    />
  );
};

export default Festival;
