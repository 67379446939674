import style from './about.module.scss';
import {
  Header,
  ScrollTopButton,
  ImageWrapper,
  HelmetWrapper,
} from '../../components';
import Slide3 from '../../assets/img/slider/me3.png';
import MemberImage from '../../assets/img/member-image.png';
import AxiosConfig from '../../utils/AxiosConfig';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useState, useEffect } from 'react';
import { LanguageData } from '../../utils/Language';

const About = () => {
  const currentLanguage = localStorage.getItem('lang');
  const langData = LanguageData[currentLanguage].about;
  const [data, setData] = useState();

  useEffect(() => {
    AxiosConfig.get(ApiRoutes.about)
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <HelmetWrapper
      title={`Mural Istanbul - ${langData.title}`}
      children={
        <div className={style.about}>
          <Header />
          <h1>{langData.title}</h1>
          <div className={style.wrapper}>
            <div className={style.text}>
              {data &&
                data.about.map(item => {
                  return (
                    <div className={style.block}>
                      <div className={style.info}>
                        <h2>{item.title[currentLanguage]}</h2>
                        <p>
                          {item.paragraph[currentLanguage]
                            .split(/\n\s*\n/)
                            .map((i, index) => {
                              return (
                                <>
                                  {index ? (
                                    <>
                                      <br />
                                      <br />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {i}
                                </>
                              );
                            })}
                        </p>
                      </div>
                      <ImageWrapper image={item.coverPhoto} />
                    </div>
                  );
                })}
            </div>
            <div className={style.team}>
              <h3>{langData.team}</h3>
              <div className={style.teamWrapper}>
                {data &&
                  data.team.map(item => {
                    return (
                      <div className={style.member}>
                        <ImageWrapper image={item.photo} />
                        <h5>
                          {item.name} <br />{' '}
                          <span>{item.title[currentLanguage]} </span>
                        </h5>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={style.partner}>
              <h3>{langData.partners}</h3>
              <div className={style.partnerWrapper}>
                {data &&
                  data.partner.map(i => (
                    <ImageWrapper className={style.rect} image={i.logo} />
                  ))}
              </div>
            </div>
          </div>

          <ScrollTopButton />
        </div>
      }
    />
  );
};

export default About;
