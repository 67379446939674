import style from './preloader.module.scss';
import PreloaderGif from '../../assets/img/muraleast_preloader.gif';

const Preloader = () => {
  return (
    <div className={style.preloader}>
      <img src={PreloaderGif} alt="gif" />
    </div>
  );
};

export default Preloader;
